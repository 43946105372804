/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* calender styles */
.MuiPickersCalendarHeader-transitionContainer {
  order: -1;
}
.MuiPickersBasePicker-pickerView {
  border: 1px solid black;
}
.MuiPaper-root.MuiPaper-rounded {
  border-radius: 0px;
}
.custom-file-upload {
  border: 1px solid #000;
  display: inline-block;
  padding: 6px 20px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Nobel";
  font-weight: 700;
}
#uploadBtn {
  display: none;
}
.MuiPickersDay-dayDisabled {
  color: #cecece !important;
}
.MuiPickersCalendarHeader-dayLabel {
  color: #000 !important;
  width: 24px !important;
}
.MuiPickersDay-daySelected {
  background-color: #91e6c8 !important;
  color: #000 !important;
}
.MuiPickersCalendar-transitionContainer {
  margin-top: 12px !important;
  min-height: 150px !important;
}
.MuiPickersBasePicker-pickerView {
  min-width: 260px !important;
  min-height: 260px !important;
  /* justify-content: start !important; */
}
.MuiPickersCalendarHeader-switchHeader .MuiIconButton-root {
  padding: 5px;
}
.MuiPickersDay-day {
  color: #000;
  width: 24px !important;
  height: 24px !important;
}

.MuiPickersCalendarHeader-switchHeader {
  width: 92%;
}
.MuiTypography-caption {
  font-family: "Nobel";
}
.MuiPaper-elevation8 {
  box-shadow: 0px 0px 12px 0px rgb(0, 0, 0, 16%) !important;
}
@media (min-width: 960px) {
  /* .MuiPaper-elevation8 {
    top: 800px !important;
    left: 514px !important;
  } */
}
.MuiButton-contained {
  box-shadow: none !important;
}
.MuiInputBase-input {
  color: #032584 !important;
  padding: 6px 20px 7px !important;
}
.MuiSvgIcon-root {
  color: #000 !important;
}
.MuiInput-underline:before {
  border-bottom: 1px solid black !important;
}
video {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
